import React from "react"

import Layout from "../components/layout"
import PageBanner from "../components/pageBanner"
import ContactMap from "../components/contactMap"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <PageBanner title="Contact Us" />
    <ContactMap />
  </Layout>
)

export default ContactPage
