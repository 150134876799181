import React, { FC, useRef } from "react"
import {Map} from "maplibre-gl"
import contact from "../support/contact"

const DEFAULT_ZOOM = 13
const DEFAULT_LAT = contact.coordinates.lat
const DEFAULT_LNG = contact.coordinates.lng

interface Props {
  zoom?: number
  lat?: number
  lng?: number
  address?: string
  email?: string
  phoneNumber?: string
  phoneNumberDisplay?: string
}

const ContactMap: FC<Props> = ({
  zoom = DEFAULT_ZOOM,
  lat = DEFAULT_LAT,
  lng = DEFAULT_LNG,
  address = contact.address,
  email = contact.email,
  phoneNumber = contact.phoneNumber,
  phoneNumberDisplay = contact.phoneNumberDisplay,
}) => {
  const colClass = phoneNumber ? 'col-lg-4 col-sm-6 col-12 d-flex' : 'col-sm-6 col-12 d-flex';
  const mapContainer = useRef(null);
  const map = useRef<any>(null);

  React.useEffect(() => {
    if (!mapContainer.current || map.current) return; // stops map from intializing more than once

    map.current = new Map({
      container: mapContainer.current,
      style: 'https://api.maptiler.com/maps/dataviz/style.json?key=my95Ua0Q41v3R2uEBgpp',
      center: [lng, lat],
      zoom: 15,
      minZoom: 15,
      maxZoom: 15,
      pixelRatio: 2,
      interactive: false,
      });
    }, []);

  return (
    <>
      <div className="page-cda contact-details-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="all-contact-details">
                <div className="row">
                  <div className={colClass}>
                    <div className="single-contact-details">
                      <div className="icon">
                        <span className="flaticon-placeholder"></span>
                      </div>
                      <h4 className="title">Our Address</h4>
                      {address.split("\n").map((line, i) => (
                        <p className="desc" key={i}>
                          {line}
                        </p>
                      ))}
                      <a
                        className="link"
                        href={`https://www.google.com/maps/@${lat},${lng},12.71z`}
                        target="_blank"
                        rel="nofollow"
                      >
                        Directions
                      </a>
                    </div>
                  </div>
                  <div className={colClass}>
                    <div className="single-contact-details">
                      <div className="icon">
                        <span className="flaticon-message"></span>
                      </div>
                      <h4 className="title">Email Address</h4>
                      <a className="desc" href={`mailto:${email}`}>
                        {email}
                      </a>
                      <a className="link" href={`mailto:${email}`}>
                        Email us
                      </a>
                    </div>
                  </div>
                  {phoneNumber && (
                  <div className={colClass}>
                    <div className="single-contact-details">
                      <div className="icon">
                        <span className="fa fa-phone"></span>
                      </div>
                      <h4 className="title">Phone</h4>
                      <a className="desc" href={`tel:${phoneNumber}`}>
                        {phoneNumberDisplay}
                      </a>
                      <a className="link" href={`tel:${phoneNumber}`}>
                        Call us
                      </a>
                    </div>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="map-area">
        <div ref={mapContainer} className="contactMap">
        </div>
      </div>
    </>
  )
}

export default ContactMap
